import Cart from "../../../models/cart/Cart";

const cart = {
    state: () => ({
        cartItems: false,
        removedItem: {},
    }),
    mutations: {
        cartPush(state, {counter, title, item}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = cart.push(counter, title, item);
        },
        cartRemove(state, {counter}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = false;
            state.cartItems = cart.remove(counter);
        },

        cartRemoveItem(state, {counter, name}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = false;
            state.cartItems = cart.removeItem(counter, name);
            state.removedItem = {counter, name}
        },

        cartRemoveCertificate(state, {counter}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = false;
            state.cartItems = cart.removeCertificate(counter);
        },
    },
    getters: {
        cartItems: state => state.cartItems,
        cartDeliveryItems({cartItems}){
            let itemsArray = Object.values(cartItems);
            let itemsKeys = Object.keys(cartItems);
            let filtered = {};
            for (let i=0; i < itemsKeys.length; i++) {
                if (itemsKeys[i] === '0'){
                    filtered = itemsArray[i];
                }
            }
            return filtered;
        },
        cartItemsWithoutDelivery({cartItems}){
            let itemsArray = Object.values(cartItems);
            let itemsKeys = Object.keys(cartItems);
            let filtered = {};
            for (let i=0; i < itemsKeys.length; i++) {
                if (itemsKeys[i] !== '0'){
                    filtered = Object.assign({}, filtered, {[itemsKeys[i]]:itemsArray[i]})
                }
            }
            return filtered;
        },
        cartAmount(state) {
            let amount = 0;
            if (typeof state.cartItems === 'object') {
                let cartArray = Object.values(state.cartItems);
                cartArray.forEach((items) => {
                    let itemsArray = Object.values(items);
                    itemsArray.forEach((item) => {
                        amount += parseFloat(item.price);
                    });
                })
            }
            return amount;
        },
        removedItem: state => state.removedItem
    }
}
export default cart