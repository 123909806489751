const payButton = {
    state: () => ({
        payButtonName: 'Pagar Custas<span class="hide-sm"> Cartoriais</span>',
        emitButtonName: 'Verificar Disponibilidade',
        processButtonName: 'Processando...',
    }),
    mutations: {
        changePayButtonName(state, buttonName) {
            state.payButtonName = buttonName;
        },
        changeEmitButtonName(state, buttonName) {
            state.emitButtonName = buttonName;
        },
        changeProcessButtonName(state, buttonName) {
            state.processButtonName = buttonName;
        },
    },
    actions: {
        changePayButtonName({ commit }, buttonName) {
            commit('changePayButtonName', buttonName);
        },
        changeEmitButtonName({ commit }, buttonName) {
            commit('changeEmitButtonName', buttonName);
        },
        changeProcessButtonName({ commit }, buttonName) {
            commit('changeProcessButtonName', buttonName);
        },
        resetButtonName({ commit }) {
            commit('changePayButtonName', 'Pagar Custas<span class="hide-sm"> Cartoriais</span>');
            commit('changeEmitButtonName', 'Verificar Disponibilidade');
            commit('changeProcessButtonName', 'Processando...');
        },
    },
    getters: {
        payButtonName: state => state.payButtonName,
        emitButtonName: state => state.emitButtonName,
        processButtonName: state => state.processButtonName,
    }
}
export default payButton