export function redireciona(contador, bloco, idPrefix = 'titulo-secao-') {
    bloco = parseInt(bloco) + 1;
    let id = `#${idPrefix}${contador}-${bloco}`;
    doScrolling(id, 600)
}

function getElementY(query) {
    return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
}

export function doScrolling(element, duration = 400) {
    setTimeout(() => {
        try {
            let startingY = window.pageYOffset;
            let elementY = getElementY(element);
            let targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
            let diff = targetY - startingY
            let easing = function (t) {
                return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
            }
            let start;

            if (!diff) return
            window.requestAnimationFrame(function step(timestamp) {
                if (!start) start = timestamp
                let time = timestamp - start
                let percent = Math.min(time / duration, 1)
                percent = easing(percent)
                window.scrollTo(0, startingY + diff * percent)
                if (time < duration) {
                    window.requestAnimationFrame(step)
                }
            })
        } catch (e) {
            //console.log(e)
        }
    }, 200);

}

// redirecionamento quando gera o alerta
export function deslocaParaErro() {
    let inputErrado = document.getElementsByClassName('input_text_error');
    let radioErrado = document.getElementsByClassName('input_radio_II_error');
    let selectErrado = document.getElementsByClassName('input_select_error');
    RetornaRecente(inputErrado[0], radioErrado[0], selectErrado[0]);
}

export function RetornaRecente(inputErrado, radioErrado, selectErrado) {

    let positionInput = getPosition(inputErrado);
    let positionRadio = getPosition(radioErrado);
    let positionSelect = getPosition(selectErrado);

    if (inputErrado === undefined) {
        positionInput = 1000000;
    } else {
        try {
            inputErrado = inputErrado.getElementsByTagName('input');
            inputErrado = inputErrado[0].id;
        } catch (e) {
            //console.log(e);
        }
    }
    if (radioErrado === undefined) {
        positionRadio = 1000000;
    } else {
        radioErrado = radioErrado.getElementsByTagName('input');
        radioErrado = radioErrado[0].id;
    }
    if (selectErrado === undefined) {
        positionSelect = 1000000;
    } else {
        selectErrado = selectErrado.getElementsByTagName('select');
        selectErrado = selectErrado[0].id;
    }

    let campo = '';

    if (positionInput < positionRadio && positionInput < positionSelect) {
        doScrolling('#' + inputErrado, 1000)
    } else if (positionRadio < positionInput && positionRadio < positionSelect) {
        doScrolling('#' + radioErrado, 1000)
    } else if (positionSelect < positionRadio && positionSelect < positionInput) {
        doScrolling('#' + selectErrado, 1000)
    }
}

// Helper function to get an element's exact position
function getPosition(el) {
    let xPos = 0;
    let yPos = 0;

    while (el) {
        if (el.tagName === "BODY") {
            // deal with browser quirks with body/window/document and page scroll
            let xScroll = el.scrollLeft || document.documentElement.scrollLeft;
            let yScroll = el.scrollTop || document.documentElement.scrollTop;

            xPos += (el.offsetLeft - xScroll + el.clientLeft);
            yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return yPos
}