import {ResourceAdapter} from "../../../../../painel/app/js/Service/Adapters/ResourceAdapter";

export default class ClientsController {
    constructor() {
        this._clients = {};
    }

    findClients() {
        return  new Promise((resolve, reject) => {
            this.setClients();
            this.sortClients();
            resolve(this._clients);
        });

        // let resource = new ResourceAdapter(INCLUDEPATH + '/ajax/sales/sales-list.php');
        // return resource.get()
        //     .then((clients) => {
        //         return clients;
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    }

    sortClients() {
        this._clients.list.sort(()=> Math.random() - 0.5);
    }

    setClients() {
        this._clients = {
            count: "118330",
            list: [
                {
                    name: "Aref Sabeh Filho",
                    region: "SP",
                    city: "Assis",
                    idtransacao: "21424915374",
                    venda_id: "999984768606864",
                    status: "approved",
                    statusvenda_data: "2022-04-07 12:13:36"
                },
                {
                    name: "Antonio Carlos Marins",
                    region: "SP",
                    city: "Itapira",
                    idtransacao: "21424725173",
                    venda_id: "999984768606850",
                    status: "approved",
                    statusvenda_data: "2022-04-07 12:07:23"
                },
                {
                    name: "Kayk de Paula",
                    region: "SP",
                    city: "Indaiatuba",
                    idtransacao: "21419608191",
                    venda_id: "999984768606724",
                    status: "approved",
                    statusvenda_data: "2022-04-07 12:03:24"
                },
                {
                    name: "Maria Irene de oliveira carvalho ",
                    region: "PI",
                    city: "Teresina",
                    idtransacao: "21422760280",
                    venda_id: "999984768606807",
                    status: "approved",
                    statusvenda_data: "2022-04-07 11:03:49"
                },
                {
                    name: "Inês Clara Dutra ",
                    region: "DF",
                    city: "Brasília",
                    idtransacao: "21421820048",
                    venda_id: "999984768606774",
                    status: "approved",
                    statusvenda_data: "2022-04-07 10:36:12"
                },
                {
                    name: "Roselaine Jacobsen ",
                    region: "SC",
                    city: "Joinville",
                    idtransacao: "21419348910",
                    venda_id: "999984768606713",
                    status: "approved",
                    statusvenda_data: "2022-04-07 08:53:52"
                },
                {
                    name: "Roberto Gonzalez y Bautista",
                    region: "BA",
                    city: "Lauro de Freitas",
                    idtransacao: "21418534259",
                    venda_id: "999984768606678",
                    status: "approved",
                    statusvenda_data: "2022-04-07 08:04:21"
                },
                {
                    name: "Luis Felipe Penteado Bartolomeu ",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21383382763",
                    venda_id: "999984768605935",
                    status: "approved",
                    statusvenda_data: "2022-04-07 02:33:51"
                },
                {
                    name: "Sergio Augusto Porto Ribeiro",
                    region: "MS",
                    city: "Dourados",
                    idtransacao: "21376333466",
                    venda_id: "999984768605718",
                    status: "approved",
                    statusvenda_data: "2022-04-07 02:32:28"
                },
                {
                    name: "CLAUDIO FERREIRA GALASSI",
                    region: "SP",
                    city: "Brotas",
                    idtransacao: "21415412197",
                    venda_id: "999984768606659",
                    status: "approved",
                    statusvenda_data: "2022-04-07 02:23:10"
                },
                {
                    name: "JULIANO WERLANG",
                    region: "RS",
                    city: "Porto Alegre",
                    idtransacao: "21414863349",
                    venda_id: "999984768606652",
                    status: "approved",
                    statusvenda_data: "2022-04-07 01:48:23"
                },
                {
                    name: "AGNO FRANCISCO SOLON VASCONCELOS",
                    region: "MT",
                    city: "Várzea Grande",
                    idtransacao: "21406542058",
                    venda_id: "999984768606362",
                    status: "approved",
                    statusvenda_data: "2022-04-06 22:27:06"
                },
                {
                    name: "ELDER ARAUJO BATISTA",
                    region: "BA",
                    city: "Feira de Santana",
                    idtransacao: "21412371360",
                    venda_id: "999984768606635",
                    status: "approved",
                    statusvenda_data: "2022-04-06 20:59:45"
                },
                {
                    name: "michele de freitas gonçalves ",
                    region: "SP",
                    city: "Itanhaém",
                    idtransacao: "21411785610",
                    venda_id: "999984768606630",
                    status: "approved",
                    statusvenda_data: "2022-04-06 20:39:23"
                },
                {
                    name: "siegmar bostelmann",
                    region: "PR",
                    city: "Curitiba",
                    idtransacao: "21411343039",
                    venda_id: "999984768606626",
                    status: "approved",
                    statusvenda_data: "2022-04-06 20:23:26"
                },
                {
                    name: "Josemir Conceição de Oliveira",
                    region: "DF",
                    city: "Brasília",
                    idtransacao: "21410612034",
                    venda_id: "999984768606610",
                    status: "approved",
                    statusvenda_data: "2022-04-06 19:58:50"
                },
                {
                    name: "UBIRAJARA GALVÃO DE OLIVEIRA ",
                    region: "MT",
                    city: "Cuiabá",
                    idtransacao: "21408544412",
                    venda_id: "999984768606590",
                    status: "approved",
                    statusvenda_data: "2022-04-06 18:56:46"
                },
                {
                    name: "Sergio Luiz Butuem ",
                    region: "SP",
                    city: "Santana de Parnaíba",
                    idtransacao: "21408424326",
                    venda_id: "999984768606588",
                    status: "approved",
                    statusvenda_data: "2022-04-06 18:55:19"
                },
                {
                    name: "cleusiane mendes de souza",
                    region: "SP",
                    city: "Campinas",
                    idtransacao: "21404714115",
                    venda_id: "999984768606540",
                    status: "approved",
                    statusvenda_data: "2022-04-06 17:00:20"
                },
                {
                    name: "Giovana Ribeiro Telo Ramos",
                    region: "SP",
                    city: "Santana de Parnaíba",
                    idtransacao: "21403723277",
                    venda_id: "999984768606512",
                    status: "approved",
                    statusvenda_data: "2022-04-06 16:27:08"
                },
                {
                    name: "Rozanilde Fonseca Correa Reis",
                    region: "MA",
                    city: "São Luís",
                    idtransacao: "21401913830",
                    venda_id: "999984768606432",
                    status: "approved",
                    statusvenda_data: "2022-04-06 15:26:10"
                },
                {
                    name: "Caroline Brand",
                    region: "RS",
                    city: "Santo Ângelo",
                    idtransacao: "21401378282",
                    venda_id: "999984768606415",
                    status: "approved",
                    statusvenda_data: "2022-04-06 15:03:19"
                },
                {
                    name: "Edimir lima da Silva ",
                    region: "AM",
                    city: "Manaus",
                    idtransacao: "21401191811",
                    venda_id: "999984768606408",
                    status: "approved",
                    statusvenda_data: "2022-04-06 15:01:21"
                },
                {
                    name: "Marina rosa Brandão de freitas",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21400848235",
                    venda_id: "999984768606399",
                    status: "approved",
                    statusvenda_data: "2022-04-06 14:47:05"
                },
                {
                    name: "Ricardo prado de freitas",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21400570887",
                    venda_id: "999984768606394",
                    status: "approved",
                    statusvenda_data: "2022-04-06 14:34:36"
                },
                {
                    name: "Helem Chavante Souza",
                    region: "DF",
                    city: "Brasília",
                    idtransacao: "21400143763",
                    venda_id: "999984768606387",
                    status: "approved",
                    statusvenda_data: "2022-04-06 14:33:50"
                },
                {
                    name: "Sonia Salle da Silva ",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21399269841",
                    venda_id: "999984768606359",
                    status: "approved",
                    statusvenda_data: "2022-04-06 13:47:27"
                },
                {
                    name: "Simone Ravanini",
                    region: "SP",
                    city: "Indaiatuba",
                    idtransacao: "21398790571",
                    venda_id: "999984768606346",
                    status: "approved",
                    statusvenda_data: "2022-04-06 13:30:56"
                },
                {
                    name: "DEISE PAIVA DE SOUZA",
                    region: "BA",
                    city: "Lapão",
                    idtransacao: "21398706096",
                    venda_id: "999984768606342",
                    status: "approved",
                    statusvenda_data: "2022-04-06 13:27:08"
                },
                {
                    name: "Bruna Franciele da Luz Desconsi",
                    region: "RS",
                    city: "Porto Alegre",
                    idtransacao: "21397768425",
                    venda_id: "999984768606321",
                    status: "approved",
                    statusvenda_data: "2022-04-06 12:59:24"
                },
                {
                    name: "Ana Carolina dos Santos ",
                    region: "RJ",
                    city: "Magé",
                    idtransacao: "21397001344",
                    venda_id: "999984768606311",
                    status: "approved",
                    statusvenda_data: "2022-04-06 12:36:40"
                },
                {
                    name: "Waltécio Galvão",
                    region: "SP",
                    city: "Itapetininga",
                    idtransacao: "21395892638",
                    venda_id: "999984768606288",
                    status: "approved",
                    statusvenda_data: "2022-04-06 12:02:07"
                },
                {
                    name: "Edgar Elias de Oliveira ",
                    region: "MT",
                    city: "Matupá",
                    idtransacao: "21394682188",
                    venda_id: "999984768606252",
                    status: "approved",
                    statusvenda_data: "2022-04-06 12:02:07"
                },
                {
                    name: "SÉRGIO DO NASCIMENTO SOUZA",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21395287562",
                    venda_id: "999984768606276",
                    status: "approved",
                    statusvenda_data: "2022-04-06 11:43:36"
                },
                {
                    name: "Renato Tavares Ferro",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21393874708",
                    venda_id: "999984768606231",
                    status: "approved",
                    statusvenda_data: "2022-04-06 10:58:08"
                },
                {
                    name: "CRISTIANE TORRES DE MIRANDA",
                    region: "SC",
                    city: "São José",
                    idtransacao: "21387037832",
                    venda_id: "999984768606068",
                    status: "approved",
                    statusvenda_data: "2022-04-06 10:47:14"
                },
                {
                    name: "LOURIVAL PEREIRA BARBOSA NETO",
                    region: "MG",
                    city: "Araguari",
                    idtransacao: "21391275826",
                    venda_id: "999984768606145",
                    status: "approved",
                    statusvenda_data: "2022-04-06 09:20:23"
                },
                {
                    name: "Liliana Raske",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21391004099",
                    venda_id: "999984768606132",
                    status: "approved",
                    statusvenda_data: "2022-04-06 09:01:31"
                },
                {
                    name: "Walquiria de Morais Pimenta",
                    region: "MG",
                    city: "Poços de Caldas",
                    idtransacao: "21390858792",
                    venda_id: "999984768606130",
                    status: "approved",
                    statusvenda_data: "2022-04-06 08:56:19"
                },
                {
                    name: "Leonardo Cardoso ",
                    region: "SC",
                    city: "Criciúma",
                    idtransacao: "21390580676",
                    venda_id: "999984768606125",
                    status: "approved",
                    statusvenda_data: "2022-04-06 08:41:25"
                },
                {
                    name: "José Nivaldo da Silva ",
                    region: "SP",
                    city: "Campinas",
                    idtransacao: "21390483976",
                    venda_id: "999984768606122",
                    status: "approved",
                    statusvenda_data: "2022-04-06 08:38:51"
                },
                {
                    name: "TIAGO BOTELHO ULHOA",
                    region: "MG",
                    city: "Paracatu",
                    idtransacao: "21390330709",
                    venda_id: "999984768606113",
                    status: "approved",
                    statusvenda_data: "2022-04-06 08:28:41"
                },
                {
                    name: "Anderson Renato Costa da Silva",
                    region: "AL",
                    city: "Maceió",
                    idtransacao: "21383223161",
                    venda_id: "999984768606055",
                    status: "approved",
                    statusvenda_data: "2022-04-05 20:38:31"
                },
                {
                    name: "Eliana de Oliveira Pinto Victor",
                    region: "SP",
                    city: "Tremembé",
                    idtransacao: "21379483032",
                    venda_id: "999984768606022",
                    status: "approved",
                    statusvenda_data: "2022-04-05 18:42:17"
                },
                {
                    name: "Conceição de Maria Melo Martins",
                    region: "DF",
                    city: "Brasília",
                    idtransacao: "21378769188",
                    venda_id: "999984768606012",
                    status: "approved",
                    statusvenda_data: "2022-04-05 18:23:30"
                },
                {
                    name: "DAISE ALVES DE MELO",
                    region: "GO",
                    city: "Cidade Ocidental",
                    idtransacao: "21378235754",
                    venda_id: "999984768606003",
                    status: "approved",
                    statusvenda_data: "2022-04-05 18:07:27"
                },
                {
                    name: "Francisca Souza da Silva",
                    region: "Ce",
                    city: "Fortaleza",
                    idtransacao: "21376892201",
                    venda_id: "999984768605978",
                    status: "approved",
                    statusvenda_data: "2022-04-05 17:24:50"
                },
                {
                    name: "Maria das Graças Ferreira",
                    region: "SP",
                    city: "Santo André",
                    idtransacao: "21375581622",
                    venda_id: "999984768605941",
                    status: "approved",
                    statusvenda_data: "2022-04-05 16:44:03"
                },
                {
                    name: "Carin Kenmotsu Coelho Garcia",
                    region: "SP",
                    city: "Guarulhos",
                    idtransacao: "21375406587",
                    venda_id: "999984768605931",
                    status: "approved",
                    statusvenda_data: "2022-04-05 16:35:52"
                },
                {
                    name: "Fábio Eduardo Bezerra Lemos e Carvalho",
                    region: "GO",
                    city: "Goiânia",
                    idtransacao: "21375362409",
                    venda_id: "999984768605928",
                    status: "approved",
                    statusvenda_data: "2022-04-05 16:33:39"
                },
                {
                    name: "Lúcia Elena Essy",
                    region: "RS",
                    city: "Taquari",
                    idtransacao: "21373168945",
                    venda_id: "999984768605842",
                    status: "approved",
                    statusvenda_data: "2022-04-05 15:21:38"
                },
                {
                    name: "CARLOS HENRIQUE PAULA DE MOURA",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21372368055",
                    venda_id: "999984768605805",
                    status: "approved",
                    statusvenda_data: "2022-04-05 14:48:27"
                },
                {
                    name: "Alexsandra Oliveira Marques ",
                    region: "CE",
                    city: "Fortaleza",
                    idtransacao: "21371986877",
                    venda_id: "999984768605793",
                    status: "approved",
                    statusvenda_data: "2022-04-05 14:35:19"
                },
                {
                    name: "Ariane Priscila Rodrigues Da Silva",
                    region: "Sp",
                    city: "São Paulo ",
                    idtransacao: "21371359136",
                    venda_id: "999984768605774",
                    status: "approved",
                    statusvenda_data: "2022-04-05 14:09:21"
                },
                {
                    name: "MARIA RITA CARVALHO SCHNEIDER",
                    region: "RS",
                    city: "Uruguaiana",
                    idtransacao: "21370749933",
                    venda_id: "999984768605761",
                    status: "approved",
                    statusvenda_data: "2022-04-05 13:52:18"
                },
                {
                    name: "Anezia Aparecida de Jesus Moreira",
                    region: "MG",
                    city: "Nova Lima",
                    idtransacao: "21370771149",
                    venda_id: "999984768605759",
                    status: "approved",
                    statusvenda_data: "2022-04-05 13:48:42"
                },
                {
                    name: "Francisco Chagas Mendes",
                    region: "SP",
                    city: "Campinas",
                    idtransacao: "21370163701",
                    venda_id: "999984768605739",
                    status: "approved",
                    statusvenda_data: "2022-04-05 13:26:41"
                },
                {
                    name: "Rui Aparecido da Costa Alves da Silva",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21369424780",
                    venda_id: "999984768605728",
                    status: "approved",
                    statusvenda_data: "2022-04-05 13:06:55"
                },
                {
                    name: "Nilce Alexandre Marui",
                    region: "SP",
                    city: "São Bernardo do Campo ",
                    idtransacao: "21369243152",
                    venda_id: "999984768605714",
                    status: "approved",
                    statusvenda_data: "2022-04-05 12:58:31"
                },
                {
                    name: "José Palermo",
                    region: "...RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21368673134",
                    venda_id: "999984768605686",
                    status: "approved",
                    statusvenda_data: "2022-04-05 12:38:13"
                },
                {
                    name: "Luiz Byron Andrade Ribeiro Pessoa",
                    region: "PE",
                    city: "Recife",
                    idtransacao: "21368479081",
                    venda_id: "999984768605690",
                    status: "approved",
                    statusvenda_data: "2022-04-05 12:31:15"
                },
                {
                    name: "Roberta Furtado Forte Papker",
                    region: "PR",
                    city: "Prado Ferreira",
                    idtransacao: "21367742754",
                    venda_id: "999984768605671",
                    status: "approved",
                    statusvenda_data: "2022-04-05 12:15:11"
                },
                {
                    name: "matheus soares lima",
                    region: "SP",
                    city: "Itaquaquecetuba",
                    idtransacao: "21367092608",
                    venda_id: "999984768605653",
                    status: "approved",
                    statusvenda_data: "2022-04-05 11:49:28"
                },
                {
                    name: "Ednelsa Rodrigues da silva ",
                    region: "MG",
                    city: "Uberaba",
                    idtransacao: "21366431121",
                    venda_id: "999984768605633",
                    status: "approved",
                    statusvenda_data: "2022-04-05 11:32:53"
                },
                {
                    name: "nelson coronado junior",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21365922238",
                    venda_id: "999984768605610",
                    status: "approved",
                    statusvenda_data: "2022-04-05 11:15:42"
                },
                {
                    name: "Sonia Cançado Paraiso",
                    region: "MG",
                    city: "Belo Horizonte",
                    idtransacao: "21365575838",
                    venda_id: "999984768605602",
                    status: "approved",
                    statusvenda_data: "2022-04-05 11:11:14"
                },
                {
                    name: "LEANA DE OLIVEIRA ALONSO",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21365041913",
                    venda_id: "999984768605585",
                    status: "approved",
                    statusvenda_data: "2022-04-05 10:49:11"
                },
                {
                    name: "Vanuza Cristina Oliveira de jesus ",
                    region: "MG",
                    city: "Brumadinho",
                    idtransacao: "21364041051",
                    venda_id: "999984768605539",
                    status: "approved",
                    statusvenda_data: "2022-04-05 10:11:41"
                },
                {
                    name: "Junot Celso Denchuk",
                    region: "PR",
                    city: "Curitiba",
                    idtransacao: "21363702514",
                    venda_id: "999984768605525",
                    status: "approved",
                    statusvenda_data: "2022-04-05 10:00:00"
                },
                {
                    name: "silvio pieroni",
                    region: "SP",
                    city: "Araçatuba",
                    idtransacao: "21363696458",
                    venda_id: "999984768605521",
                    status: "approved",
                    statusvenda_data: "2022-04-05 09:59:36"
                },
                {
                    name: "Emerson Marques Rehder ",
                    region: "RO",
                    city: "Rolim de Moura",
                    idtransacao: "21362338813",
                    venda_id: "999984768605486",
                    status: "approved",
                    statusvenda_data: "2022-04-05 09:01:52"
                },
                {
                    name: "Juliana de Cassia Serrano",
                    region: "SP",
                    city: "Presidente Prudente",
                    idtransacao: "21361137391",
                    venda_id: "999984768605451",
                    status: "approved",
                    statusvenda_data: "2022-04-05 07:58:46"
                },
                {
                    name: "Ana Paula gomes bittencourt ",
                    region: "PA",
                    city: "Belém",
                    idtransacao: "21338374681",
                    venda_id: "999984768604918",
                    status: "approved",
                    statusvenda_data: "2022-04-05 06:58:09"
                },
                {
                    name: "Cezar José Magalhães Ferreira",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21323303324",
                    venda_id: "999984768604649",
                    status: "approved",
                    statusvenda_data: "2022-04-05 06:57:12"
                },
                {
                    name: "Elizabeth de Lacerda Abreu Hanson",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21319568203",
                    venda_id: "999984768604630",
                    status: "approved",
                    statusvenda_data: "2022-04-05 06:51:55"
                },
                {
                    name: "André Paulo Junges",
                    region: "RJ",
                    city: "São Gonçalo",
                    idtransacao: "21358589329",
                    venda_id: "999984768605430",
                    status: "approved",
                    statusvenda_data: "2022-04-05 00:14:27"
                },
                {
                    name: "Maurício Martins Chaoul",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21355693108",
                    venda_id: "999984768605397",
                    status: "approved",
                    statusvenda_data: "2022-04-04 21:10:52"
                },
                {
                    name: "Luiza Mello ribeiro ",
                    region: "MT",
                    city: "Cuiabá",
                    idtransacao: "21355218280",
                    venda_id: "999984768605392",
                    status: "approved",
                    statusvenda_data: "2022-04-04 20:52:39"
                },
                {
                    name: "Carlos Vicente Almeida Moraes ",
                    region: "SP",
                    city: "Mirassol",
                    idtransacao: "21349782196",
                    venda_id: "999984768605334",
                    status: "approved",
                    statusvenda_data: "2022-04-04 17:38:57"
                },
                {
                    name: "Erik Elderlan Serra da Silva ",
                    region: "AM",
                    city: "Tabatinga",
                    idtransacao: "21348863388",
                    venda_id: "999984768605325",
                    status: "approved",
                    statusvenda_data: "2022-04-04 17:05:34"
                },
                {
                    name: "Lourival Pereira Barbosa Meto",
                    region: "MG",
                    city: "Araguari",
                    idtransacao: "21347336819",
                    venda_id: "999984768605285",
                    status: "approved",
                    statusvenda_data: "2022-04-04 16:08:38"
                },
                {
                    name: "Lourival Pereira Barbosa Neto",
                    region: "MG",
                    city: "Araguari",
                    idtransacao: "21347061905",
                    venda_id: "999984768605276",
                    status: "approved",
                    statusvenda_data: "2022-04-04 16:02:45"
                },
                {
                    name: "Mariano Modolo",
                    region: "SC",
                    city: "Gaspar",
                    idtransacao: "21347211228",
                    venda_id: "999984768605278",
                    status: "approved",
                    statusvenda_data: "2022-04-04 16:02:00"
                },
                {
                    name: "marinea ferreira lima",
                    region: "RJ",
                    city: "Rio de Janeiro",
                    idtransacao: "21347043331",
                    venda_id: "999984768605268",
                    status: "approved",
                    statusvenda_data: "2022-04-04 15:57:43"
                },
                {
                    name: "Rui dos Santos Alves",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21346966877",
                    venda_id: "999984768605267",
                    status: "approved",
                    statusvenda_data: "2022-04-04 15:55:21"
                },
                {
                    name: "maria do rosario aguiar marques",
                    region: "Alagoas",
                    city: "Maceió",
                    idtransacao: "21346111038",
                    venda_id: "999984768605211",
                    status: "approved",
                    statusvenda_data: "2022-04-04 15:15:08"
                },
                {
                    name: "THIAGO ALBERTI FARIA",
                    region: "PR",
                    city: "Curitiba",
                    idtransacao: "21345306523",
                    venda_id: "999984768605186",
                    status: "approved",
                    statusvenda_data: "2022-04-04 14:56:53"
                },
                {
                    name: "Marta Beatriz Mandagaran Gallo",
                    region: "PR",
                    city: "Curitiba",
                    idtransacao: "21344892852",
                    venda_id: "999984768605162",
                    status: "approved",
                    statusvenda_data: "2022-04-04 14:29:53"
                },
                {
                    name: "TANIA MARIA BOHANA HAYASHI",
                    region: "SP",
                    city: "São José dos Campos",
                    idtransacao: "21344892001",
                    venda_id: "999984768605158",
                    status: "approved",
                    statusvenda_data: "2022-04-04 14:26:33"
                },
                {
                    name: "maria lucia prado collaço",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21343649948",
                    venda_id: "999984768605116",
                    status: "approved",
                    statusvenda_data: "2022-04-04 13:42:55"
                },
                {
                    name: "Diego Martins Gonçalez ",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21343285536",
                    venda_id: "999984768605099",
                    status: "approved",
                    statusvenda_data: "2022-04-04 13:26:49"
                },
                {
                    name: "Rosângela Virgínia Faé ",
                    region: "SP",
                    city: "Campinas",
                    idtransacao: "21343179576",
                    venda_id: "999984768605093",
                    status: "approved",
                    statusvenda_data: "2022-04-04 13:22:53"
                },
                {
                    name: "Thiago Uejima Muzilli",
                    region: "PE",
                    city: "Recife",
                    idtransacao: "21343078590",
                    venda_id: "999984768605091",
                    status: "approved",
                    statusvenda_data: "2022-04-04 13:19:18"
                },
                {
                    name: "euter julian morello",
                    region: "ES",
                    city: "Linhares",
                    idtransacao: "21341835527",
                    venda_id: "999984768605047",
                    status: "approved",
                    statusvenda_data: "2022-04-04 12:37:48"
                },
                {
                    name: "Vanessa Viviane Sampaio Silva Sardela",
                    region: "PA",
                    city: "Belém",
                    idtransacao: "21341676784",
                    venda_id: "999984768605044",
                    status: "approved",
                    statusvenda_data: "2022-04-04 12:33:19"
                },
                {
                    name: "Isabella Morais Tavares Faria",
                    region: "MG",
                    city: "Dores do Indaiá",
                    idtransacao: "21341404757",
                    venda_id: "999984768605037",
                    status: "approved",
                    statusvenda_data: "2022-04-04 12:25:42"
                },
                {
                    name: "Américo Genzini",
                    region: "SP",
                    city: "São Paulo",
                    idtransacao: "21340620530",
                    venda_id: "999984768605015",
                    status: "approved",
                    statusvenda_data: "2022-04-04 11:58:37"
                },
                {
                    name: "Raudinei Torres Rangel ",
                    region: "MG",
                    city: "Ribeirão das Neves",
                    idtransacao: "21340437707",
                    venda_id: "999984768605009",
                    status: "approved",
                    statusvenda_data: "2022-04-04 11:52:08"
                },
                {
                    name: "IARA ANTUNES VIANNA",
                    region: "Distrito Federal",
                    city: "Brasilia",
                    idtransacao: "21340437202",
                    venda_id: "999984768605005",
                    status: "approved",
                    statusvenda_data: "2022-04-04 11:50:05"
                },
                {
                    name: "CHRISTOVÃO DE MOURA VAROTTO JÚNIOR",
                    region: "DF",
                    city: "Brasília",
                    idtransacao: "21339536573",
                    venda_id: "999984768604965",
                    status: "approved",
                    statusvenda_data: "2022-04-04 11:22:42"
                }
            ]
        }
    }
}