const sale = {
    state: () => ({
        saleId: '',
        saleLevel: 1,
        saleFormData: {},
        salePaying: false,
        saleButtonActive: true,
        saleInternational: false,
        termsAgree: false,
    }),
    mutations: {
        saleChangeId(state, saleId) {
            state.saleId = saleId;
        },
        saleChangeLevel(state, payload) {
            state.saleLevel = payload;
        },
        saleChangeFormData(state, payload) {
            state.saleFormData = payload;
        },
        salePayingChange(state, booleanPaying){
            state.salePaying = booleanPaying;
        },
        saleButtonActiveChange(state, booleanButton){
            state.saleButtonActive = booleanButton;
        },
        saleChangeInternational(state, saleInternational) {
            state.saleInternational = saleInternational;
        },
        saleTermsAgree(state, payload) {
            state.termsAgree = payload;
        },
    },
    actions: {
        saleChangeLevel({ commit, dispatch }, payload) {
            commit('saleChangeLevel', payload);
            dispatch('resetButtonName');
        },
        blockPayButton({ commit }) {
            commit('saleButtonActiveChange', false);
        },
        saleTermsAgree({ commit }, payload) {
            commit('saleTermsAgree', payload);
        },
    },
    getters: {
        saleId: state => state.saleId,
        saleLevel: state => state.saleLevel,
        saleFormData: state => state.saleFormData,
        salePaying: state => state.salePaying,
        saleButtonActive: state => state.saleButtonActive,
        saleInternational: state => state.saleInternational,
        termsAgree: state => state.termsAgree,
    }
}
export default sale