import 'es6-promise/auto';
import store from "./store";
const SelectService = () => import("./components/services/certificate/SelectService");
const TellUs = () => import("./components/services/message/TellUs");
const BannerClients = () => import("./components/services/message/BannerClients");
const MainContent = () => import("./components/services/MainContent");
const MapService = () => import("./components/services/certificate/MapService");

import VueMask from 'v-mask';
Vue.use(VueMask);

export const bus = new Vue();

let app = new Vue({
    data: {
        tempoRecarga: 1000,
        dataCalcPrice: {},
        emailRecusado: true,
        message: '',
        tellUs: false,
    },
    methods: {
        reverseMessage() {
            this.message = this.message.split('').reverse().join('')
        },
    },
    provide() {
      return {
          includePath: INCLUDEPATH,
          domain: DOMAIN
      }
    },
    store: store,
    components: {
        SelectService,
        TellUs,
        BannerClients,
        MainContent,
        MapService,
    },
    created: function () {
        document.getElementById('spin-loading').remove();
    }
}).$mount('#app');
export {app};